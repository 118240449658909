'use client';

import * as React from 'react';
import type { Action, State } from './menu-context';
import { MenuContext } from './menu-context';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case 'CLOSE_MENU':
      return {
        ...state,
        isOpen: state.isOpen ? false : state.isOpen,
      };
    default:
      return state;
  }
};

const initialContext: State = {
  isOpen: false,
};

export function MenuProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialContext);

  const value: MenuContext = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}
