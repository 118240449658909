'use client';

import * as React from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { useMenuActions } from '@noirproperties/react/menu-provider/menu-hooks';

export function CloseMenuOnRouteChange() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { closeMenu } = useMenuActions();

  React.useEffect(() => {
    closeMenu();
  }, [pathname, searchParams, closeMenu]);

  return null;
}
