import * as React from 'react';
import { MenuContext } from './menu-context';

function useMenuContext() {
  const context = React.useContext(MenuContext);

  if (!context) {
    throw new Error('`useMenuContext` must be used within a <MenuProvider>');
  }
  return context;
}

export function useMenuState() {
  const { state } = useMenuContext();

  return {
    isOpen: state.isOpen,
  };
}

export function useMenuActions() {
  const { dispatch } = useMenuContext();

  const toggleMenu = React.useCallback(() => {
    dispatch({ type: 'TOGGLE_MENU' });
  }, [dispatch]);

  const closeMenu = React.useCallback(() => {
    dispatch({ type: 'CLOSE_MENU' });
  }, [dispatch]);

  return {
    toggleMenu,
    closeMenu,
  };
}
