'use client';

import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import { cn } from '@noirproperties/ui/lib/utils';
import {
  useMenuActions,
  useMenuState,
} from '@noirproperties/react/menu-provider/menu-hooks';

type HamburgerMenuButtonProps = {
  className?: string;
};

export function HamburgerMenuButton({ className }: HamburgerMenuButtonProps) {
  const { toggleMenu } = useMenuActions();
  return (
    <button
      className={cn(
        '-m-2.5 rounded-md p-2.5 text-gunmetal stroke-linen lg:hidden',
        className,
      )}
      onClick={() => {
        toggleMenu();
      }}
      type="button"
    >
      <span className="sr-only">Open main menu</span>
      <Bars3Icon aria-hidden="true" className="h-6 w-6" />
    </button>
  );
}

export function CloseButton() {
  const { toggleMenu } = useMenuActions();
  return (
    <button
      className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
      onClick={() => {
        toggleMenu();
      }}
      type="button"
    >
      <span className="sr-only">Close menu</span>
      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
    </button>
  );
}

export function MobileMenu({ children }: { children: React.ReactNode }) {
  const { isOpen } = useMenuState();
  const { toggleMenu } = useMenuActions();

  return (
    <Dialog
      as="div"
      onClose={() => {
        toggleMenu();
      }}
      open={isOpen}
    >
      <Dialog.Panel className="fixed inset-0 z-[99] overflow-y-auto bg-linen px-6 py-2 lg:hidden">
        {children}
      </Dialog.Panel>
    </Dialog>
  );
}
