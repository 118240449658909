'use client';

import * as React from 'react';

export type State = {
  isOpen: boolean;
};

export type Action = {
  type: 'TOGGLE_MENU' | 'CLOSE_MENU';
};

export type MenuContext = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const MenuContext = React.createContext<MenuContext | null>(null);
